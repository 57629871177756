import { DateTime, Interval } from 'luxon';

export class DateUtils {
  static toJSDate(input: string | Date | null | undefined): Date | undefined {
    if (input === null || input === undefined) {
      return undefined;
    }

    const browserTimezone = DateTime.local().zoneName;

    if (input instanceof Date) {
      return isNaN(input.getTime()) ? undefined : input;
    }

    if (typeof input === 'string') {
      // Attempt to parse as an ISO string
      const dateTimeISO = DateTime.fromISO(input, { zone: browserTimezone });
      if (dateTimeISO.isValid) {
        return dateTimeISO.toJSDate();
      }

      // Attempt to parse as a common date string format
      const dateTimeFromFormat = DateTime.fromFormat(input, 'yyyy-MM-dd', {
        zone: browserTimezone,
      });
      if (dateTimeFromFormat.isValid) {
        return dateTimeFromFormat.toJSDate();
      }

      // Additional formats can be added here as needed

      return undefined;
    }

    return undefined;
  }

  static toDateFormattedString(
    input: string | Date | null | undefined,
    format: string
  ): string {
    {
      const date = DateUtils.toJSDate(input);
      if (date === undefined) {
        return '';
      }
      return DateTime.fromJSDate(date).setLocale('de').toFormat(format);
    }
  }

  static getCalendarWeeks(
    startDate: string | Date,
    endDate: string | Date
  ): Array<{
    weekNumber: number;
    year: number;
    startOfWeek: DateTime;
    endOfWeek: DateTime;
  }> {
    // Convert inputs to Luxon DateTime objects
    const start =
      typeof startDate === 'string'
        ? DateTime.fromISO(startDate)
        : DateTime.fromJSDate(startDate);

    const end =
      typeof endDate === 'string'
        ? DateTime.fromISO(endDate)
        : DateTime.fromJSDate(endDate);

    if (!start.isValid || !end.isValid) {
      throw new Error('Invalid date format');
    }

    // Create interval between the two dates
    const interval = Interval.fromDateTimes(start, end);

    // Set up results array
    const calendarWeeks: Array<{
      weekNumber: number;
      year: number;
      startOfWeek: DateTime;
      endOfWeek: DateTime;
    }> = [];

    // Start with the first week that contains the start date
    let currentDate = start.startOf('week');

    // German locale for week calculation - weeks start on Monday and the first week
    // of the year must have at least 4 days in the new year
    const germanOptions = { locale: 'de' };

    // Loop through each week until we reach the end date
    while (currentDate <= end) {
      // Get the week number according to German rules (ISO 8601)
      const weekNumber = currentDate.weekNumber;
      const year = currentDate.year;

      // Get start and end of this week
      const startOfWeek = currentDate;
      const endOfWeek = currentDate.endOf('week');

      // Only add weeks that are in the interval
      if (
        interval.contains(startOfWeek) ||
        interval.contains(endOfWeek) ||
        (startOfWeek < start && endOfWeek > start)
      ) {
        calendarWeeks.push({
          weekNumber,
          year,
          startOfWeek,
          endOfWeek,
        });
      }

      // Move to next week
      currentDate = currentDate.plus({ weeks: 1 });
    }

    return calendarWeeks;
  }

  static getCalendarWeek(
    input: string | Date | null | undefined,
    withYear: boolean = false
  ) {
    const date = DateUtils.toJSDate(input);
    if (date === undefined) {
      return undefined;
    }
    const res = DateTime.fromJSDate(date)
      .toUTC()
      .setZone('Europe/Berlin')
      .setLocale('de');
    if (withYear) {
      return `${res.weekNumber}/${res.weekYear}`;
    }
    return res.weekNumber;
  }

  static getStartDate(
    input: string | Date | null | undefined
  ): Date | undefined {
    const date = DateUtils.toJSDate(input);
    if (date === undefined) {
      return undefined;
    }

    return DateTime.fromJSDate(date)
      .toUTC()
      .setZone('Europe/Berlin')
      .startOf('day')
      .toJSDate();
  }

  static getEndDate(input: string | Date | null | undefined): Date | undefined {
    const date = DateUtils.toJSDate(input);
    if (date === undefined) {
      return undefined;
    }

    return DateTime.fromJSDate(date)
      .toUTC()
      .setZone('Europe/Berlin')
      .endOf('day')
      .set({ millisecond: 0 })
      .toJSDate();
  }
}
